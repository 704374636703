import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '../services/events-service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
  searchForm: FormGroup;
  places: string[] = [];
  isMobile: boolean = false;
  formattedStartDate: string | null = null;
  formattedEndDate: string | null = null; 

  constructor(private eventsService: EventsService, private router: Router, private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      inputSearchText: [''],
      startDate: [''],
      endDate: [''],
      selectedLocation: ['']
    });
  }

  ngOnInit(): void {
    this.loadPlaces();
  
    this.searchForm.valueChanges.subscribe(values => {
      this.formattedStartDate = values.startDate ? this.formatDate(new Date(values.startDate)) : null;
      this.formattedEndDate = values.endDate ? this.formatDate(new Date(values.endDate)) : null;
      this.onFilterChange();
    });

    this.isMobile = window.innerWidth < 769;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = window.innerWidth < 769; 
  }

  loadPlaces(): void {
    this.eventsService.getEvents().subscribe(eventData => {
      const uniquePlaces = new Set<string>();
      eventData.forEach(event => {
        event.dates.forEach(date => {
          uniquePlaces.add(date.place_state);
        });
      });
      this.places = Array.from(uniquePlaces);
    });
  }

  searchEvents(): void {
    let { inputSearchText, selectedLocation, startDate, endDate } = this.searchForm.value;

    const normalizedInputText = this.normalizeText(inputSearchText);

    if (normalizedInputText) {
        const matchingPlace = this.places.find(place => 
            this.normalizeText(place) === normalizedInputText
        );

        if (matchingPlace) {
            selectedLocation = matchingPlace;
            inputSearchText = ''; 
        }
    }

    console.log("REVISION",startDate, endDate)

    const formattedStartDate = startDate ? this.formatDate(new Date(startDate)) : null;
    const formattedEndDate = endDate ? this.formatDate(new Date(endDate)) : null;

    console.log("REVISION 2", formattedStartDate, formattedEndDate)

    const queryParams: any = {
        place: selectedLocation,
        text: inputSearchText,
        startDate: formattedStartDate,
        endDate: formattedEndDate
    };

    this.router.navigate(['/events/events-search'], { queryParams });
}


  private normalizeText(text: string): string {
    return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '';
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}-${day}-${year}`; 
  }
  
  applyFilters(): void {
    this.searchEvents();
  }

  clearFilters(): void {
    this.searchForm.patchValue({
        inputSearchText: '',
        startDate: '',
        endDate: '',
        selectedLocation: '' 
    });


    if (this.router.url.startsWith('/events/events-search')) {
        this.router.navigate(['/events/events-search'], { queryParams: {} });
    }
}

  
  onFilterChange(): void {
    if (this.router.url.startsWith('/events/events-search')) {
      this.searchEvents();
    }
  }


}

