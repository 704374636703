import {AfterViewInit, HostListener, Component, ElementRef,Input,Output, EventEmitter, OnInit, Renderer2, ViewChild,ChangeDetectorRef, Inject} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {NavbarService} from '../services/navbar.service';
import {LocalizationService} from '../services/localization.service';
import {LanguageService} from '../services/langaje.service';
import {CategoriesService} from "../services/categories.service";
import {EventsService} from "../services/events-service";
import {fromEvent, Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {NgProgress, NgProgressRef} from "ngx-progressbar";
import {AppUsersService} from "../services/app-users.service";
import {NgxSpinnerService} from "ngx-spinner";
import Swal from "sweetalert2";
declare var $: any; // Declaración para usar jQuery
import { trigger, state, style, animate, transition } from '@angular/animations';
import {HttpClient} from "@angular/common/http";
import {MapsAPILoader} from "@agm/core";
import {DOCUMENT,DatePipe} from "@angular/common";
import {add} from "ionicons/icons";
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { AppService } from '../core/services/app.service';
import { StorageService } from '../core/services/storage_service';
declare var google: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('in2', style({
        transform: 'translateX(-100%)',
        opacity: 1
      })),
      state('out', style({
        transform: 'translateX(100%)',
        opacity: 1
      })),
      state('out2', style({
        transform: 'translateX(-100%)',
        opacity: 1
      })),
      transition('in => out', animate('600ms ease-out')),
      transition('in => out2', animate('600ms ease-out')),
      transition('out2 => in', animate('600ms ease-in'))
    ])
  ]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  events: any[] = [];

  //nuevo boton para el slider
  isSliderOpen = false; // Estado inicial del slider
  //end nuevo boton del slider
 
  currentIndex: number = 0;
  private timer: any;
  menuToggleChecked = false;
  menuToggleChecked2 = false;
  localization: any;
  showDropdown = false;
  numbers = [];
  userLogged = false;
  dropdownVisible: boolean = false;
  dropdownVisibleEstados: boolean = false;
  // Define your variables here
  formatter: any; // Agrega esta línea para definir formatter
  fromDate: any; // Define las variables fromDate y toDate según tus requisitos
  toDate: any;
  hoveredDate: any;
  eventosBanner: any[] = [];
  isSelected: boolean = false;

  mobileMenuOpenBtn: NodeListOf<Element>;
  mobileMenuCloseBtn: NodeListOf<Element>;
  mobileMenu: NodeListOf<Element>;
  overlay: Element;
  accordionBtn: NodeListOf<Element>;
  accordion: NodeListOf<Element>;

  // columnas y subcolumnas de categorías
  columnOneTitle;
  subColumnOne = [];
  columnTwoTitle;
  subColumnTwo = [];
  columnThreeTitle;
  subColumnThree = [];
  columnFourTitle;
  subColumnFour = [];
  eventOne;
  eventTwo;
  eventThree;
  eventFour;

  reservations = [];

  lastKeyBoardType;
  progressRef: NgProgressRef;

  currentUser;
  authLoading = false;

  inputSearchText = '';
  localStorage = window.localStorage;

  myDate;
  mySecondDate;

  animationRunning = false;

  @ViewChild('myModal') myModal: ElementRef;
  showDatePicker = false;
  categories = [];
  estados = [];
  userCurrentState;
  currentRoute = '';
  @Output() currentLanguage2: EventEmitter<string> = new EventEmitter<string>();
  @Input() currentLanguage: string;
  currentLang: string;
  private languageSubscription: Subscription;
  constructor(
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private auth: AngularFireAuth,
    private router: Router,
    public navbarService: NavbarService,
    private localizationService: LocalizationService,
    private languageService: LanguageService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private categoriesService: CategoriesService,
    private eventsService: EventsService,
    private progress: NgProgress,
    private usersService: AppUsersService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private mapsAPILoader: MapsAPILoader,
    private appService: AppService,
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
     // Configuración del idioma por defecto (español)
    //  this.translateService.setDefaultLang('mx');
     // Carga los archivos de traducción
    //  this.translateService.use('en');
    // this.setAppLang();
    this.numbers = Array(100).fill(100).map((x, i) => i); // [0,1,2,3,4]

  }

  // setAppLang():void{
  //   this.translateService.setDefaultLang('mx');
  //   this.translateService.use(this.translateService.getBrowserLang()!);
  // }  

  isAccordionOpen = false;
  isAccordionLanguageOpen = false;
  isAccordionCountryOpen =false;

  slideActive = false;
  state = 'in';
  nextClicked = true;
  previousClicked = false;

  toggleSlider() {
    this.isSliderOpen = !this.isSliderOpen; // Cambia el estado del slider
    console.log("Slider en movil nuevo:",  this.isSliderOpen);
  }

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }

  toggleAccordionLanguage() {
    this.isAccordionLanguageOpen = !this.isAccordionLanguageOpen;
  }

  toggleAccordionCountry(){
    this.isAccordionCountryOpen = !this.isAccordionCountryOpen;
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const menuBox = this.elementRef.nativeElement.querySelector('.menu__box');
    const menuToggle = this.elementRef.nativeElement.querySelector('#menu__toggle');
    const menuToggle2 = this.elementRef.nativeElement.querySelector('#menu__toggle2');
    if(menuBox && menuToggle && menuToggle2){
      const isClickInsideMenu = menuBox.contains(event.target);
      const isClickOnToggle = menuToggle.contains(event.target);
      const isClickOnToggle2 = menuToggle2.contains(event.target);

      if (!isClickInsideMenu && !isClickOnToggle) {
        this.menuToggleChecked = false;
      }

      if (!isClickInsideMenu && !isClickOnToggle2) {
        this.menuToggleChecked2 = false;
      }
    }

  }

  toggleMenu() {
    this.menuToggleChecked = !this.menuToggleChecked;
  }

  toggleMenu2() {
    this.menuToggleChecked2 = !this.menuToggleChecked2;
  }

  selectCategory2(category: any) {
    // Lógica para seleccionar la categoría
    this.isSelected = true; // Activa la clase btn-selected
  }

  // changeToEnglish() {
  //   this.translateService.use('en');
  // }

  // changeToEsp() {
  //   this.translateService.use('es');
  // }

  changeToEnglish() {
    const uid = this.navbarService.user ? this.navbarService.user.uid : '';
    this.languageService.changeToEnglish(uid);
  }

  changeToEsp() {
    const uid = this.navbarService.user ? this.navbarService.user.uid : '';
    this.languageService.changeToEsp(uid);
  }
  

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
    // const navbar = document.querySelector("#miNavbar");
    // const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    // if (currentScroll == 0) {
      
    //   navbar.classList.add('is-sticky');
      
    // } else {
      
    //   navbar.classList.add('is-sticky');
    // }

    // if (currentScroll >= 0) {
    //   // Estamos en la parte superior de la página
    //   navbar.classList.add('is-sticky');
    //   // navbar.classList.remove('is-sticky'); original funcion
    // } else {
    //   // No estamos en la parte superior de la página
    //   navbar.classList.add('is-sticky');
    // }
  // }

  ngOnInit(): void {
    // GetConfigurations
    this.appService.getConfigurations().then((data) => {
      this.storageService.saveSessionStorage('configuration', data);
    });

    //servicio de idiomas
    this.currentLang = this.languageService.getCurrentLanguage();

    // Suscripción al observable para detectar cambios de idioma
    this.languageSubscription = this.languageService
      .getCurrentLanguageObservable()
      .subscribe((newLang) => {
        this.currentLang = newLang;
      });
    //end servicio de idiomas

    //scroll

    //end scroll


    //carga de elementos
    this.mobileMenuOpenBtn = document.querySelectorAll(
      "button[data-mobile-menu-open-btn]"
    );

    // if (this.mobileMenuOpenBtn.length > 0) {
    //   console.log("Los elementos se han cargado correctamente:", this.mobileMenuOpenBtn);
    // } else {
    //   console.log("No se han encontrado elementos con el atributo data-mobile-menu-open-btn.");
    // }
    //end carga de elementos

    this.currentLanguage = this.languageService.getCurrentLanguage();
    this.languageService
      .getCurrentLanguageObservable()
      .subscribe((language) => {
        this.currentLanguage = language;
      });

    this.currentRoute = this.router.url;
    if (this.currentRoute === "/home/main") {
      $(".js-height-full").height($(window).height());

      $(window).resize(function () {
        $(".js-height-full").height($(window).height());
      });
    } else {
      // Si no estamos en la ruta '/home/main', establecer el height de .home-banner a 0px
      // $(".home-banner").height(0);
    }

    // Verificar si el usuario está en un dispositivo móvil
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      // Fijar el fondo en dispositivos móviles
      $("#home, .hire-section").css({ "background-attachment": "fixed" });
    } else {
      // Aplicar efecto de paralaje en otros dispositivos
      // $('#home, .hire-section').parallax('50%', 0.1);
    }

    //$('.header').sticky({
    //topSpacing: 0
    //});

    this.progressRef = this.progress.ref("myProgress");
    this.authLoading = true;
    this.auth.onAuthStateChanged((user) => {
      this.authLoading = false;
      if (user) {
        this.navbarService.user = user;
        this.usersService.getUser(user.uid).subscribe((doc) => {
          this.currentUser = doc.data();
          if (!this.currentUser) {
            this.userLogged = false;
            this.appService.isLoggedUser = false;
            this.navbarService.user = null;
            //this.auth.signOut();
          } else {
            this.appService.isLoggedUser = true;
            this.userLogged = true;
          }
          // descargar compras del usuario (reservations)
          this.eventsService
            .getUserReservations(user.uid)
            .subscribe((reservationsData) => {
              const arr = [];
              reservationsData.forEach((r) => {
                if (r.openpay_transaction_id.length > 1) {
                  arr.push(r);
                }
              });
              this.reservations = arr;
              // console.log('this.reservations', this.reservations);
            });
        });
      } else {
        this.userLogged = false;
        this.appService.isLoggedUser = false;
        this.navbarService.user = null;
        this.auth.signOut();
      }
    });

    // language
    this.localizationService.getData("mx").subscribe((result) => {
      this.localization = result;
    });

    // get categories
    this.categoriesService.getCategoriesOnce().subscribe((categoriesData) => {
      // get subcategories of the first four categories
      this.eventsService.getEvents().subscribe((events) => {
        // filtrar solo eventos publicados
        var filtered = [];
        // events.forEach((e) => {
        //   e.dates = this.removeExpiredDatesFromEvent(e);
        //   if (e.dates.length > 0) {
        //     if (e.post) {
        //       filtered.push(e);
        //     }
        //   }
        // });
        this.events = filtered;
        // Comenzar el temporizador para cambiar las imágenes automáticamente cada 3 segundos
        this.startTimer();

        // obtener estados para autocomplete de nombre de ciudad
        this.http.get<any>("././assets/estados.json").subscribe((data) => {
          // convertir a array
          // solo mostrar los estados en los que haya evento
          // propiedad place_state de la fecha
          var array: any[] = [];
          const mapStates = {};
          for (const key in data) {
            this.events.forEach((event) => {
              if (event.post) {
                event.dates.forEach((date) => {
                  if (date.place_state === data[key].name) {
                    mapStates[key] = data[key];
                  }
                });
              }
            });
          }
          for (const key in mapStates) {
            array.push(mapStates[key]);
          }
          this.estados = array as Array<any>;
        });

        // 0. filtrar para mostrar solo las categorías de las que haya eventos existentes
        var mapCategories = {};
        categoriesData.forEach((category) => {
          this.events.forEach((event) => {
            if (event.category === category.id) {
              mapCategories[category.id] = category;
            }
          });
        });
        // después actualizar "categoriesData"
        var arr = [];
        for (let key in mapCategories) {
          arr.push(mapCategories[key]);
        }
        categoriesData = arr;
        this.categories = arr;

        // column one
        if (categoriesData[0]) {
          this.columnOneTitle = categoriesData[0];
          this.categoriesService
            .getSubcategories(this.columnOneTitle.id)
            .subscribe((subdata) => {
              this.subColumnOne = subdata;
              // console.log('this.subOne', this.subColumnOne);
            });
        }

        // column two
        if (categoriesData[1]) {
          this.columnTwoTitle = categoriesData[1];
          this.categoriesService
            .getSubcategories(this.columnTwoTitle.id)
            .subscribe((subdata) => {
              this.subColumnTwo = subdata;
              // console.log('this.subTwo', this.subColumnTwo);
            });
        }

        // column three
        if (categoriesData[2]) {
          this.columnThreeTitle = categoriesData[2];
          this.categoriesService
            .getSubcategories(this.columnThreeTitle.id)
            .subscribe((subdata) => {
              this.subColumnThree = subdata;
              // console.log('this.subThree', this.subColumnThree);
            });
        }

        // column four
        if (categoriesData[3]) {
          this.columnFourTitle = categoriesData[3];
          this.categoriesService
            .getSubcategories(this.columnFourTitle.id)
            .subscribe((subdata) => {
              this.subColumnFour = subdata;
              // console.log('this.subFour', this.subColumnFour);
            });
        }
      });
    });

    this.eventsService.getEvents().subscribe((eventsData) => {
      var filtered = [];
      // eventsData.forEach((e) => {
      //   e.dates = this.removeExpiredDatesFromEvent(e);
      //   if (e.dates.length > 0 && e.post && filtered.length < 5) {
      //     if (e.post) {
      //       filtered.push(e);
      //     }
      //   }
      // });
      this.events = filtered;
      this.eventosBanner = this.events;

      //const arr = [this.events[0]];
      //this.events = arr;

      const randomNumbers = this.getRandomNumbers(
        this.events.length,
        this.events.length
      );
      this.eventOne = this.events[randomNumbers[0]];
      this.eventTwo = this.events[randomNumbers[1]];
      this.eventThree = this.events[randomNumbers[2]];
      this.eventFour = this.events[randomNumbers[3]];
      // tiene que aver siempre al menos un evento si hay menos siempre se muestra el primero
      if (!this.eventTwo) {
        this.eventTwo = this.eventOne;
      }
      if (!this.eventThree) {
        this.eventThree = this.eventOne;
      }
      if (!this.eventFour) {
        this.eventFour = this.eventOne;
      }
    });

    // Inicializa fromDate y toDate según tus requisitos
    this.fromDate = {}; // Inicializa con tu fecha predeterminada o null
    this.toDate = {}; // Inicializa con tu fecha predeterminada o null
    this.formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  ngOnDestroy(): void {
    // Limpiar el temporizador al destruir el componente para evitar fugas de memoria
    this.clearTimer();
  }

  startTimer(): void {
    this.timer = setInterval(() => {
      this.nextEvent();
    }, 12000); // Cambiar la imagen cada 3 segundos
  }

  clearTimer(): void {
    clearInterval(this.timer);
    this.timer = null;
  }


  buscarBoletos(placeId, id_event){
    this.eventsService.getEventOnce(id_event).subscribe(eventData => {
      var position = 0;
      eventData.data().dates.forEach((d, index) => {
        if (d.place_id === placeId){
          position = index;
        }
      });
       this.router.navigate(['events/event-form'], { queryParams: { id: id_event, date_position: position } });
    });
  }


  goToSelectedEventDate2(eventId: string) {
    // Implementa la lógica para ir a la página de detalles del evento con el ID proporcionado
    // Por ejemplo, podrías usar el servicio Router para navegar a la página de detalles del evento
  }

  nextEvent() {
    this.animationRunning = true;
      this.nextClicked = true;
      this.previousClicked = false;
      this.state = 'out2';
      setTimeout(() => {
        this.state = 'out';
        // console.log('Valor actual de currentIndex antes de nextEvent:', this.currentIndex);
        this.currentIndex = (this.currentIndex + 1) % this.events.length;
        // console.log('Valor actual de currentIndex después de nextEvent:', this.currentIndex);
        setTimeout(() => {
            this.state = 'in';
            this.clearTimer();
            this.startTimer();
            this.animationRunning = false;
        }, 0);
    }, 500);
  }

  previousEvent() {
    this.nextClicked = false;
    this.previousClicked = true;
      this.state = 'out';
    setTimeout(() => {
      this.state = 'in2';
        console.log('Valor actual de currentIndex antes de previousEvent:', this.currentIndex);
        this.currentIndex = (this.currentIndex - 1 + this.events.length) % this.events.length;
        console.log('Valor actual de currentIndex después de previousEvent:', this.currentIndex);
        setTimeout(() => {
            this.state = 'in';
            this.clearTimer();
            this.startTimer();
        }, 0);
    }, 500);
  }



  // Agrega este método para manejar la selección de la fecha
    // Agrega esta función para validar la entrada (ajusta esto según tus necesidades)
    validateInput(currentDate: any, inputValue: string): any {
      // Implementa la lógica para validar la entrada y devuelve la fecha válida
      // o maneja la entrada de alguna manera
      return currentDate; // Otra lógica de validación aquí
    }

  onDateSelection(date: any) {
  //return;
    //this.spinner.show('mySpinner');
    const currentDate = new Date();
    // Maneja la fecha seleccionada, puedes asignarla a fromDate o toDate según sea necesario
    console.log('Fecha seleccionada:', date);

    if (!this.myDate){
      this.myDate = new Date(date.year, date.month - 1, date.day);
      console.log('Date object', this.myDate);
      return;
    } else {
      this.mySecondDate = new Date(date.year, date.month - 1, date.day);
      console.log('Second Date object', this.mySecondDate);
    }
    this.mySecondDate.setHours(23);
    this.mySecondDate.setMinutes(59);
    this.mySecondDate.setSeconds(59);
    this.spinner.show('mySpinner');
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    this.myDate.setSeconds(1); // tweak para encuentre eventos del mismo día también
    /*if (this.myDate.getTime() < currentDate.getTime()){
      console.log('myDate', this.myDate);
      console.log('currentDate', currentDate);
      Swal.fire('Atención1', 'No hay eventos en la fecha seleccionada', 'warning');
      this.spinner.hide('mySpinner');
      return;
    }*/

    // recorrer todos los eventos y obtener los que tengan fechas después de la fecha seleccionada
    // const endDate = r.entrance.toDate(); // Assuming endDate is a Firestore Timestamp
    // return endDate < currentDate;
    var newArr = [];
    const mapEvents = {};
    this.events.forEach(event => {
      event.dates.forEach(d => {
        const endDate = d.date.toDate(); // Assuming endDate is a Firestore Timestamp
        if (endDate >= this.myDate && endDate <= this.mySecondDate){
          mapEvents[event.id] = event;
        }
        /*if (endDate <= this.mySecondDate){
          mapEvents[event.id] = event;
        }*/
      });
    });
    // colocar los eventos en el array
    for (const key in mapEvents){
      newArr.push(mapEvents[key]);
    }
    if (newArr.length === 0){
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          // confirmButton: "btn btn-custom",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: true
      });
      const mensajeSinEventosTitulo = "<span style='padding: 0% 15% 0% 15%'>" + this.translateService.instant('mensajeSinEventosTitulo') + "</span>";
      swalWithBootstrapButtons.fire({
        title: mensajeSinEventosTitulo,
        // title: "<span style='padding: 0% 15% 0% 15%'>No hay eventos en el rango de fecha que seleccionaste</span>",
        // title: "No hay eventos en el rango de fecha que seleccionaste",
        text: this.translateService.instant('mensajeSinEventosTexto'),
        // icon: "error",
        showCancelButton: false,
        confirmButtonText:this.translateService.instant('botonDeAcuerdo'),
        confirmButtonColor: "#b7bf28",
        cancelButtonText: "No, cancel!",
        width: '600px',
        imageUrl: "./assets/images/close-modal.png",
        imageWidth: 70,
        imageHeight: 70,
        // padding: "32px",
      });    
      this.spinner.hide('mySpinner');
      this.myDate = null;
      this.mySecondDate = null;
      return;
    }
    console.log('events date search', newArr);
    newArr.sort((a, b) => a.dates[0].startTime - b.dates[0].startTime);

    this.eventsService.array = newArr;
    this.eventsService.date = this.myDate;
    this.eventsService.endDate = this.mySecondDate;
    // mandar a componente de búsqueda por texto
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.router.navigate(['events/events-search']);
          this.spinner.hide('mySpinner');
        }, 200);
      });
    }, 1000);

  }

/**
     * Funciones de modal con date picker
     */
openModal() {
  console.log('open modal date picker');
  this.showDatePicker = true;
  this.myModal.nativeElement.style.display = 'block';
}

closeModal() {
  this.showDatePicker = false;
  this.myModal.nativeElement.style.display = 'none';
}


  // Agrega este método para verificar si una fecha está en el rango
  isRange(date: any): boolean {
    // Implementa tu lógica para verificar si la fecha está en el rango
    return false;
  }

  // Agrega este método para verificar si una fecha está resaltada
  isHovered(date: any): boolean {
    // Implementa tu lógica para verificar si la fecha está resaltada
    return false;
  }

  // Agrega este método para verificar si una fecha está dentro
  isInside(date: any): boolean {
    // Implementa tu lógica para verificar si la fecha está dentro
    return false;
  }

  ngAfterViewInit(): void {
    this.closeModal();
    // Initialize your variables and event listeners here
    // this.mobileMenuOpenBtn = document.querySelectorAll('[data-mobile-menu-open-btn]');
    this.mobileMenuCloseBtn = document.querySelectorAll('[data-mobile-menu-close-btn]');
    this.mobileMenu = document.querySelectorAll('[data-mobile-menu]');
    this.overlay = document.querySelector('[data-overlay]');
    this.accordionBtn = document.querySelectorAll('[data-accordion-btn]');
    this.accordion = document.querySelectorAll('[data-accordion]');

    for (let i = 0; i < this.mobileMenuOpenBtn.length; i++) {

      // mobile menu function
      const mobileMenuCloseFunc = () => {
        this.mobileMenu[i].classList.remove('active');
        if (this.overlay) {
          this.overlay.classList.remove('active');
        }
      }

      this.mobileMenuOpenBtn[i].addEventListener('click', () => {
        this.mobileMenu[i].classList.add('active');
        if (this.overlay) {
          this.overlay.classList.add('active');
        }
      });

      if (this.mobileMenuCloseBtn[i]){
        this.mobileMenuCloseBtn[i].addEventListener('click', mobileMenuCloseFunc);
      }
      if (this.overlay) {
        this.overlay.addEventListener('click', mobileMenuCloseFunc);
      }
    }
    this.closeModal();
  }

  logout(): void {
    this.auth.signOut();
    this.router.navigate(['/home/main']);
    setTimeout(() => {
      //window.location.reload();
    }, 1000);
  }

  toggleDropdown(): void {
    // this.showDropdown = !this.showDropdown;
    this.router.navigate(['/sesion/inicio']);
    this.navbarService.hideNavbar = true;
    console.log(this.showDropdown);
  }

  goToHome(): void {
    this.router.navigate(['/home/main']);
  }

  goToCountry(): void{
    this.router.navigate(['/sesion/country'])
  }

  goToLogin(): void {
    this.router.navigate(['/sesion/inicio']);
  }

  goToProfile(): void {
    this.router.navigate(['/user/profile']);
  }

  goToPurchases(): void {
    this.router.navigate(['/user/purchases']);
  }

  goToPayments(): void {
    this.router.navigate(['user/payment-methods']);
  }

  goToSavedEvents(): void {
    this.router.navigate(['/user/events']);
  }

  /**
   * Localization
   */
  setLanguage(event: any): void {
    // language
    console.log('language', event.target.value);
    this.localizationService.getData(event.target.value).subscribe((result) => {
      this.localization = result;
      console.log('localization Service', this.localization); // You can do something with the data here
    });
  }

  /**
   * Navigation
   */

  goToSelectedEventDate(eventId): void {
    this.router.navigate(['/events/select-event-date', eventId ? eventId : '']);
  }

  getRandomNumber(max): number {
    // Generate a random number between 0 (inclusive) and 1 (exclusive)
    const randomNumber = Math.random();

    // Scale the random number to the range [0, 1, 2, 3]
    const randomInt = Math.floor(randomNumber * max); // 4 because (0, 1, 2, 3)

    return randomInt;
  }


  /**
   * Aux functions
   */

  getRandomNumbers(max, count) {
    if (max < count) {
      // You can't generate more unique numbers than the max value.
      throw new Error('Count must be less than or equal to the maximum value.');
    }

    const randomNumbers = [];
    while (randomNumbers.length < count) {
      const randomNumber = Math.floor(Math.random() * max);
      if (!randomNumbers.includes(randomNumber)) {
        randomNumbers.push(randomNumber);
      }
    }
    //console.log('randomNumber', randomNumbers);
    return randomNumbers;
  }

  /**
   * Search input
   */

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    //console.log('input value', inputValue);
    this.lastKeyBoardType = new Date();
    //this.searchEventsText(inputValue);
    this.inputSearchText = inputValue;
  }

  scrollToFirstFilter(): void {
    //this.progressRef.start();
    this.spinner.show('mySpinner');
    // hacemos un scroll hacia la sección donde se ven los resultados de búsqueda
    setTimeout(() => {
      // this.progressRef.complete();
      this.spinner.hide('mySpinner');
      document.getElementById('first-filter-scroll').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }, 100);
  }

  searchEventsText() {
    this.searchEventsByText(this.inputSearchText);
  }

  removeAccents(theString: string){
    const str = theString;
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  searchEventsByText(text: string){
    if (!text){
      this.eventsService.updateData(null);
      return;
    }
    this.spinner.show('mySpinner');
    text = this.removeAccents(text.toLowerCase()).replace(' ', '');
    const arr = [];
    this.events.forEach(event => {
      if (this.removeAccents(event.name.toLowerCase()).replace(' ', '').includes(text)){
        arr.push(event);
      }
    });
    //this.eventsService.updateData(arr);

    if (arr.length === 0){
      this.spinner.hide('mySpinner');
      const mensajeSinNombreTitulo = "<span style='padding: 0px 15% 0px 15%'>" + this.translateService.instant('mensajeSinNombreTitulo') + "</span>";
      Swal.fire({
        title:  mensajeSinNombreTitulo,
        text: this.translateService.instant('mensajeSinNombreTexto'),
        // icon: 'error',
        confirmButtonText: this.translateService.instant('botonDeAcuerdo'), // Cambia el texto del botón de confirmación
        confirmButtonColor: "#b7bf28",
        cancelButtonText: 'Cancelar', // Cambia el texto del botón de cancelar (si se muestra)
        width: '600px',
        imageUrl: "./assets/images/close-modal.png",
        imageWidth: 70,
        imageHeight: 70,
      }); 
      return;
    }

    // verificar que todos los eventos tengan al menos una fecha agregada
    var newArr = [];
    arr.forEach(e => {
      if (e.dates.length > 0){
        newArr.push(e);
      }
    });

    if (newArr.length === 0){
      this.spinner.hide('mySpinner');
      Swal.fire('Atención', 'No hay resultados de búsqueda', 'warning');
      return;
    }

    this.eventsService.array = newArr;
    // mandar a componente de búsqueda por texto
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.spinner.hide('mySpinner');
          this.router.navigate(['events/events-search']);
        }, 200);
      });
    }, 1000);
  }

  searchEventsByState(text: string){
    if (!text){
      this.eventsService.updateData(null);
      return;
    }
    this.spinner.show('mySpinner');
    text = this.removeAccents(text.toLowerCase()).replace(' ', '');
    const arr = [];
    this.events.forEach(event => {
      const arrDates = [];
      event.dates.forEach(date => {
        if (date.place_state){
          if (this.removeAccents(date.place_state.toLowerCase()).replace(' ', '').includes(text)){
            if (date.post){
              arrDates.push(date);
            }
          }
        }
      });
      event.dates = arrDates;
      arr.push(event);
    });
    //this.eventsService.updateData(arr);

    if (arr.length === 0){
      this.spinner.hide('mySpinner');
      Swal.fire('Atención', 'No hay resultados de búsqueda', 'warning');
      return;
    }

    // verificar que todos los eventos tengan al menos una fecha agregada
    var newArr = [];
    arr.forEach(e => {
      if (e.dates.length > 0){
        newArr.push(e);
      }
    });

    if (newArr.length === 0){
      this.spinner.hide('mySpinner');
      Swal.fire('Atención', 'No hay resultados de búsqueda', 'warning');
      return;
    }

    this.eventsService.array = newArr;
    // mandar a componente de búsqueda por texto
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.spinner.hide('mySpinner');
          this.router.navigate(['events/events-search']);
        }, 200);
      });
    }, 1000);
  }

  filterByCategory(id: string){
    //this.progressRef.start();
    this.spinner.show('mySpinner');
    this.scrollToFirstFilter();
    // descargar eventos por query de categoría
    this.eventsService.getEventsByCategory(id).subscribe(eventsData => {
      //this.progressRef.complete();
      this.spinner.hide('mySpinner');
      this.eventsService.updateData(eventsData);
      //this.progressRef.complete();
      this.spinner.hide('mySpinner');
    });
  }


  /**
   * Botón de búsqueda en la barra de navegación
   */


  /**
   * Funciones de localStorage
   */
  saveObjectToLocalStorage(key: string, obj: any) {
    this.localStorage.setItem(key, JSON.stringify(obj));
  }


  /**
   * Local storage functions
   */

  toggleDropdownNew() {
    this.dropdownVisible = !this.dropdownVisible;
    console.log('DropdownAbierto',this.dropdownVisible);
  }

  toggleDropdownNewEstados() {
    this.dropdownVisibleEstados = !this.dropdownVisibleEstados;
    console.log('DropdownAbiertoEstados',this.dropdownVisibleEstados);
  }

  getObjectFromLocalStorage(key: string): any {
    const storedItem = this.localStorage.getItem(key);
    if (storedItem) {
      return storedItem;
    }
    return null;
  }

  selectCategory(cat){
    this.spinner.show('mySpinner');
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.eventsService.categorySelectedData.data = {
            isCategory: true,
            category: cat
          };
          this.spinner.hide('mySpinner');
          this.router.navigate(['events/events-category']);
        }, 200);
      });
    }, 1000);
  }

  selectSubcategory(sub){
    this.spinner.show('mySpinner');
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.eventsService.categorySelectedData.data = {
            isCategory: false,
            subcategory: sub
          };
          this.spinner.hide('mySpinner');
          this.router.navigate(['events/events-category']);
      }, 200);
      });
    }, 1000);
  }

  onEnterPressed(event: KeyboardEvent): void {
    // Check if the pressed key is Enter
    if (event.key === 'Enter') {
      // Your logic for Enter key press
      console.log('Enter key pressed!');
      setTimeout(() => {
        this.searchEventsByText(this.inputSearchText);
      });
    }
  }


  /**
   * Función para en cada evento solo dejar las fechas vigentes
   */
  // removeExpiredDatesFromEvent(event){
  //   const currentDate = new Date();
  //   var validDates = [];
  //   event.dates.forEach(date => {
  //     const entranceDate = date.date.toDate();
  //     const entranceTime = date.purchaseLimitTime.toDate();
  //     entranceDate.setHours(entranceTime.getHours());
  //     entranceDate.setMinutes(entranceTime.getMinutes());
  //     if (entranceDate > currentDate){
  //       if (date.post){
  //         validDates.push(date);
  //       }
  //     }
  //   });
  //   return validDates;
  // }

  /**
   * Función para saber si algún "addressComponent" de la ubicación obtenida
   * es un estado (lo comparamos con el arreglo de estados)
   */

  checkIfStateInAddressComponent(addressComponents){
    this.estados.forEach(estado => {
      addressComponents.forEach(a => {
        if (this.removeAccents(a.long_name.toLowerCase()).includes(this.removeAccents(estado.name.toLowerCase()))){
          this.userCurrentState = estado.name;
        }
      });
    });
    console.log('this.estados', this.estados);
    console.log('Estado actual del usuario', this.userCurrentState);
    return this.userCurrentState;
  }

  /**
   * Funciones de geolocalización
   */


  getLocation() {

    // en 20 segundos ocultar el spinner en caso de que no haya sido aceptado el permiso de ubicación
    setTimeout(() => {
      this.spinner.hide('mySpinner');
    }, 20000);

    if ('geolocation' in navigator) {
      this.spinner.show('mySpinner');
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.getCountryFromCoordinates(latitude, longitude);
      });
    } else {
      console.log('Geolocation is not supported in this browser.');
    }
  }
  getCountryFromCoordinates(latitude: number, longitude: number) {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(latitude, longitude);

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const addressComponents = results[0].address_components;
            console.log('addressComponents', addressComponents);
            this.spinner.hide('mySpinner');
            // obtener el nombre del estado
            if (!this.checkIfStateInAddressComponent(addressComponents)){
              Swal.fire('Lo sentimos', 'Parece que no hay eventos en tu ciudad', 'warning');
            } else {
              this.searchEventsByState(this.checkIfStateInAddressComponent(addressComponents));
            }

            for (const component of addressComponents) {
              if (component.types.includes('country')) {
                const country = component.long_name;
                console.log('User is in', country);
                if (!this.document.URL.includes('localhost')){
                  if (country.toLowerCase().includes('mexico')){
                    if (!this.document.URL.includes('.mx')){ //esta condición es para que no se cicle redireccionando
                      // window.location.href = 'https://tixygo.com.mx/';
                    }
                  }
                }
              }
            }
          } else {
            this.spinner.hide('mySpinner');
            console.log('No results found');
          }
        } else {
          this.spinner.hide('mySpinner');
          console.log('Geocoder failed due to:', status);
        }
      });
    });
  }

}














